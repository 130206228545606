import Cookies from "js-cookie";

export default class Consent {
    constructor(version, lastCheck, consentData, consentOptions) {
        this.version = version;
        this.lastCheck = lastCheck;
        this.consentData = consentData;
        this.consentOptions = consentOptions;
    }

    fromJSON(json) {
        Object.assign(this, json);
    }
    fromCookie() {
        let cookie = Cookies.get("consent");
        if(cookie != null && cookie != "") {
            let json = JSON.parse(cookie);
            if(json != null && json != "") {
                Object.assign(this, json);
            }
        }
    }
    toJSON() {
        return Object.getOwnPropertyNames(this).reduce((a, b) => {
          a[b] = this[b];
          return a;
        }, {});
    }
    toCookie() {
        Cookies.set("consent", JSON.stringify(this), { expires: 365 });
    }


      
}

export class ConsentHandler {
    constructor(requiredPermissions) {
        this.consent = new Consent();
        this.consent.fromCookie();
        this.requiredPermissions = Permissions[requiredPermissions];
        this.outdatedConsent = false;
        this.additionalConsentRequired = false;
        this.newConsent = false;

        if(this.consent.consentOptions == null) {
            this.newConsent = true;
            this.consent.consentOptions = ConsentOptions;
            
            this.requiredPermissions.requiredOptions.forEach (option => {this.consent.consentOptions[option] = true});
            this.requiredPermissions.enabledByDefault.forEach (option => {this.consent.consentOptions[option] = true});

        }
    }

    static appendScript(scriptSrc, attributes, place = "head") {
        const el = document.createElement('script')
        el.src = scriptSrc
        for (let [key, value] of Object.entries(attributes)) {
            el.setAttribute(key, value)
        }
        document.getElementsByTagName(place)[0].appendChild(el)
    }

    async check(setConsentHandler, preflightBack) {
        if(this.needsUpdate()) {
            var myHeaders = new Headers();
            /*
            if (process.env.NODE_ENV == 'development') {
              myHeaders.append("CF-Access-Client-Id", process.env.REACT_APP_CF_ID);
              myHeaders.append("CF-Access-Client-Secret", process.env.REACT_APP_CF_SECRET);
            }

             */
            
            var requestOptions = {
              method: 'GET',
              headers: myHeaders,
              redirect: 'follow'
            };
            const response = await fetch(process.env.REACT_APP_YSNDIT_ENDPOINT + "api/client/consent", requestOptions);
                const data = await response.json();
                  this.consent.consentData.termsUrl = data.termsUrl;
                  this.consent.consentData.privacyUrl = data.privacyUrl;
                  this.consent.consentData.imprintUrl = data.imprintUrl;
                  this.consent.consentData.siteName = data.siteName;

                  this.consent.consentData.adsUrl = data.ads_url;
                  this.consent.consentData.whatChanged = data.what_changed;

                  this.consent.consentData.description = data.description;
                  this.consent.consentData.favicon = data.favicon;
                  this.consent.consentData.logo = data.logo;
                  this.consent.consentData.uploadBackground = data.uploadBackground;
                  this.consent.consentData.downloadBackground = data.downloadBackground;
                  this.consent.consentData.helpUrl = data.helpUrl;

                  this.consent.consentData.stripe_publishable_key = data.stripe_publishable_key;
                  this.consent.consentData.stripe_pricing_table_id = data.stripe_pricing_table_id;
                  this.consent.consentData.premium_purchasable = data.premium_purchasable;

                  if((this.consent.version != parseInt(data.version)) && !this.newConsent) {
                    this.outdatedConsent = true;
                  }
                  this.consent.version = data.version;
                  this.consent.lastCheck = Date.now();
                  preflightBack(this.consent.consentData);
                  setConsentHandler(this);
                  return this.hasConsent();
             
        }
        else {
            preflightBack(this.consent.consentData);
            setConsentHandler(this);
            return this.hasConsent();
        }
    }

    needsUpdate() {
        if(this.consent == null || this.consent.lastCheck == null || this.consent.consentData == null) {
            this.consent.consentData = new ConsentData();
            return true;
        } else {
            let difference = Date.now() - this.consent.lastCheck;
            let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
            return (totalDays >= 2);
        }
    }

    hasConsent() {
        if(this.outdatedConsent || this.newConsent) {
            return false;
        }

        this.requiredPermissions.requiredOptions.forEach (option => {
            if(!this.consent.consentOptions[option]) {
                this.additionalConsentRequired = true;
            }
        });

        if(this.additionalConsentRequired) {
            this.requiredPermissions.requiredOptions.forEach (option => {this.consent.consentOptions[option] = true});
            this.requiredPermissions.enabledByDefault.forEach (option => {this.consent.consentOptions[option] = true});
        }
        else {
            this.saveConsent(this.consent.consentOptions, false);
        }

        return !this.additionalConsentRequired;
    }

    saveConsent(consentOptions, reject) {
        this.consent.consentOptions = consentOptions;
        if(reject) {
            Object.keys(ConsentOptions).forEach (option => {
                if(!this.requiredPermissions.requiredOptions.includes(option)) {
                    this.consent.consentOptions[option] = false;
                }
            });
        }
        this.consent.toCookie();
        return this.consent.toJSON();
    }
}

export class ConsentData {
    constructor(siteName, termsUrl, privacyUrl, adsUrl, whatChanged, description, favicon, logo, uploadBackground, downloadBackground, helpUrl, imprintUrl) {
        this.siteName = siteName;
        this.termsUrl = termsUrl;
        this.privacyUrl = privacyUrl;
        this.imprintUrl = imprintUrl;
        this.adsUrl = adsUrl;
        this.whatChanged = whatChanged;
        
        this.description = description;
        this.favicon = favicon;
        this.logo = logo;
        this.uploadBackground = uploadBackground;
        this.downloadBackground = downloadBackground;
        this.helpUrl = helpUrl;
    }
}

const ConsentOptions = {
    terms: false,
    personalData: false,
    browseridCookie: false,
    sessionCookie: false,
    fingerprint: false,
    analytics: false,
    analyticsCookie: false,
    adsCookie: false,
    adsPersonalized: false,
};

export const Permissions = {
	upload: {
        name: "upload",
        requiredOptions: ["terms", "personalData", "browseridCookie", "sessionCookie", "fingerprint", "analytics"],
        enabledByDefault: ["analyticsCookie"], // requiredOptions are auto enabled
        hiddenOptions: ["adsCookie", "adsPersonalized"],
        ipSharedWith3rdParty: ["Cloudflare", "Posthog", "Atlassian", "Dicebear", "Bunny Net", "Spartez Software", "Apple", "Mailchimp", "AWS"]
    },
	download: {
        name: "download",
        requiredOptions: ["terms", "personalData", "sessionCookie", "analytics", "adsCookie"],
        enabledByDefault: ["adsPersonalized", "analyticsCookie"], // requiredOptions are auto enabled
        hiddenOptions: ["browseridCookie", "fingerprint"],
        ipSharedWith3rdParty: ["Google", "Cloudflare", "Posthog", "Atlassian"]
    },
}

