import _sodium from 'libsodium-wrappers-sumo';

let sodiumIsReady = false;
export const uppyEncryptReady = async () => {
    if (!sodiumIsReady) {
        await _sodium.ready;
        sodiumIsReady = true;
    }
};

export const CHUNK_SIZE = 5 * 1024 * 1024;
export const SIGNATURE = 'uppyencrypt';

// Init Sodium
let sodium;
(async () => {
    await _sodium.ready;
    sodium = _sodium;
})();

export default class UppyDecrypt {
    key;
    state;
    stream;
    streamController;
    contentType;

    index = 0;

    constructor(password, salt, header) {
        const saltUint = sodium.from_base64(salt, sodium.base64_variants.URLSAFE_NO_PADDING);
        const headerUint = sodium.from_base64(header, sodium.base64_variants.URLSAFE_NO_PADDING);

        this.stream = new ReadableStream({
            start: (controller) => {
                this.streamController = controller;
            },
        });
        this.contentType = ''; // Defined if/when meta-data is decrypted

        this.key = sodium.crypto_pwhash(
            sodium.crypto_secretstream_xchacha20poly1305_KEYBYTES,
            password,
            saltUint,
            sodium.crypto_pwhash_OPSLIMIT_INTERACTIVE,
            sodium.crypto_pwhash_MEMLIMIT_INTERACTIVE,
            sodium.crypto_pwhash_ALG_ARGON2ID13
        );

        this.state = sodium.crypto_secretstream_xchacha20poly1305_init_pull(headerUint, this.key);

        this.index = SIGNATURE.length + saltUint.length + headerUint.length;
    }

    /**
     * Validates that the provided password is correct
     * @param hash The hash value of the password created during UppyEncrypt
     * @param password The user-provided password
     * @returns {bool} true if correct password
     */
    static verifyPassword(hash, password) {
        return sodium.crypto_pwhash_str_verify(hash, password);
    }

    /**
     * Decrypts the provided file
     * @param file Blob of encryptyed file
     * @returns Decrypted file as a blob
     */
    async decryptFile(file) {
        if (!this.streamController) {
            throw new Error('Encryption stream does not exist');
        }

        while (this.index < file.size) {
            const chunk = await file.slice(this.index, this.index + CHUNK_SIZE + sodium.crypto_secretstream_xchacha20poly1305_ABYTES).arrayBuffer();
            const decryptedChunk = sodium.crypto_secretstream_xchacha20poly1305_pull(this.state, new Uint8Array(chunk));

            this.streamController.enqueue(decryptedChunk.message);

            this.index += CHUNK_SIZE + sodium.crypto_secretstream_xchacha20poly1305_ABYTES;
        }

        this.streamController.close();

        const response = new Response(this.stream, { headers: { 'Content-Type': this.contentType } });
        return response.blob();
    }

    /**
     *
     * @param header Header created during encryption of the meta data
     * @param meta Encrypted meta data string
     * @returns object of the decrypted meta data
     */
    getDecryptedMetaData(header, meta) {
        // Init fresh state
        const state = sodium.crypto_secretstream_xchacha20poly1305_init_pull(sodium.from_base64(header, sodium.base64_variants.URLSAFE_NO_PADDING), this.key);
        const decryptedChunk = sodium.crypto_secretstream_xchacha20poly1305_pull(state, sodium.from_base64(meta, sodium.base64_variants.URLSAFE_NO_PADDING));

        if (!decryptedChunk) throw new Error('Unable to decrypt meta data');
        const decryptedMeta = JSON.parse(new TextDecoder().decode(decryptedChunk.message));
        if (decryptedMeta.type) this.contentType = decryptedMeta.type;
        return decryptedMeta;
    }
}

export class UppyDecryptStream {
    key;
    state;
    stream;
    streamController;
    contentType;

    startingFromByte = 0;

    constructor(password, salt, header) {
        const saltUint = sodium.from_base64(salt, sodium.base64_variants.URLSAFE_NO_PADDING);
        const headerUint = sodium.from_base64(header, sodium.base64_variants.URLSAFE_NO_PADDING);

        this.stream = new ReadableStream({
            start: (controller) => {
                this.streamController = controller;
            },
        });
        this.contentType = ''; // Defined if/when meta-data is decrypted

        this.key = sodium.crypto_pwhash(
            sodium.crypto_secretstream_xchacha20poly1305_KEYBYTES,
            password,
            saltUint,
            sodium.crypto_pwhash_OPSLIMIT_INTERACTIVE,
            sodium.crypto_pwhash_MEMLIMIT_INTERACTIVE,
            sodium.crypto_pwhash_ALG_ARGON2ID13
        );

        this.state = sodium.crypto_secretstream_xchacha20poly1305_init_pull(headerUint, this.key);

        this.startingFromByte = SIGNATURE.length + saltUint.length + headerUint.length;
    }

    /**
     * Validates that the provided password is correct
     * @param hash The hash value of the password created during UppyEncrypt
     * @param password The user-provided password
     * @returns {bool} true if correct password
     */
    static verifyPassword(hash, password) {
        return sodium.crypto_pwhash_str_verify(hash, password);
    }

    static createProcessedStream(originalStream, bytesToSkip, chunkSizeInBytes) {
        // Ziel: die ersten 100 Bytes überspringen
        const BYTES_TO_SKIP = bytesToSkip;
        const BLOCK_SIZE = chunkSizeInBytes;

        // Variablen für den aktuellen Stand
        let skippedBytes = 0;
        let buffer = new Uint8Array(CHUNK_SIZE * 20);
        let length = 0;

        // Neuer ReadableStream, um das Ergebnis zu erzeugen
        return new ReadableStream({
            async start(controller) {
                const reader = originalStream.getReader();
                while (true) {
                    // Lese Daten vom originalen Stream
                    const { done, value } = await reader.read();
                    if (done) {
                        // Beende den neuen Stream, wenn das Original beendet ist
                        break;
                    }



                    // Verbinde neue Daten mit verbleibenden Daten im Puffer
                    buffer.set(value, length)
                    length += value.length
                    //buffer = new Uint8Array([...buffer, ...value]);

                    // Solange es mehr als 20 Bytes gibt, die verarbeitet werden können
                    while (length >= BLOCK_SIZE) {
                        if (skippedBytes < BYTES_TO_SKIP) {
                            // Überspringe die ersten 100 Bytes
                            const remainingSkip = BYTES_TO_SKIP - skippedBytes;
                            const toSkip = Math.min(length, remainingSkip);
                            const toKeep = buffer.slice(toSkip)
                            buffer = new Uint8Array(CHUNK_SIZE * 20);
                            buffer.set(toKeep, 0)
                            length -= toSkip;
                            skippedBytes += toSkip;

                        } else {
                            // Gib 20-Byte-Blöcke an den neuen Stream weiter
                            const chunk = buffer.slice(0, BLOCK_SIZE);
                            controller.enqueue(chunk);
                            const toKeep = buffer.slice(BLOCK_SIZE);
                            buffer = new Uint8Array(CHUNK_SIZE * 20);
                            buffer.set(toKeep, 0)
                            length -= BLOCK_SIZE;
                        }
                    }
                }

                // Falls noch verbleibende Daten im Puffer sind, gib diese aus
                if (length > 0 && skippedBytes >= BYTES_TO_SKIP) {
                    controller.enqueue(buffer.slice(0, length));
                }
                controller.close();
            }
        });
    }

    /**
     * Decrypts the provided file
     * @param file stream of encryptyed file
     * @returns Decrypted file as a blob
     */
    async decryptFile(fileStream) {
        if (!this.streamController) {
            throw new Error('Encryption stream does not exist');
        }

        const processedStream = UppyDecrypt.createProcessedStream(fileStream, this.startingFromByte, CHUNK_SIZE + sodium.crypto_secretstream_xchacha20poly1305_ABYTES);
        const reader = processedStream.getReader();

        while (true) {
            const { done, value } = await reader.read();
            if (done) break;

            const decryptedChunk = sodium.crypto_secretstream_xchacha20poly1305_pull(this.state, value);
            this.streamController.enqueue(decryptedChunk.message);
        }

        this.streamController.close();

        const response = new Response(this.stream, { headers: { 'Content-Type': this.contentType } });
        return response.body;

    }

    /**
     *
     * @param header Header created during encryption of the meta data
     * @param meta Encrypted meta data string
     * @returns object of the decrypted meta data
     */
    getDecryptedMetaData(header, meta) {
        // Init fresh state
        const state = sodium.crypto_secretstream_xchacha20poly1305_init_pull(sodium.from_base64(header, sodium.base64_variants.URLSAFE_NO_PADDING), this.key);
        const decryptedChunk = sodium.crypto_secretstream_xchacha20poly1305_pull(state, sodium.from_base64(meta, sodium.base64_variants.URLSAFE_NO_PADDING));

        if (!decryptedChunk) throw new Error('Unable to decrypt meta data');
        const decryptedMeta = JSON.parse(new TextDecoder().decode(decryptedChunk.message));
        if (decryptedMeta.type) this.contentType = decryptedMeta.type;
        return decryptedMeta;
    }
}